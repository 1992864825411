<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '700px' }"
    header="Cliente Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <span
            ><strong>RAZ&Oacute;N SOCIAL: </strong>
            <span class="p-invalid">*</span></span
          >
          <InputText
            id="nombre"
            v-model.trim="data_cliente.nombre"
            required="true"
            autofocus
          />
          <small class="p-invalid" v-if="errors.nombre">{{
            errors.nombre[0]
          }}</small>
        </div>

        <div class="field col-11 md:col-3">
          <span for="ci_nit"><strong>CI/NIT: </strong></span>
          <InputText id="ci_nit" v-model="data_cliente.ci_nit" />
          <small class="p-invalid" v-if="errors.ci_nit">{{
            errors.ci_nit[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>FECHA NAC.: </strong> </span>
          <InputText
            v-model="data_cliente.fecha_nacimiento"
            type="date"
            :max="new Date().toISOString().split('T')[0]"
          />
          <small class="p-invalid" v-if="errors.fecha_nacimiento">{{
            errors.fecha_nacimiento[0]
          }}</small>
        </div>

        <div class="field col-12 md:col-3">
          <span for="telefono"><strong>TEL&Eacute;FONO: </strong></span>
          <InputText
            id="telefono"
            v-model="data_cliente.telefono"
            required="true"
          />
          <small class="p-invalid" v-if="errors.telefono">{{
            errors.telefono[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-3">
          <span for="tipo_cliente_id"
            ><strong>TIPO CLIENTE: </strong
            ><span class="p-invalid">*</span></span
          >
          <Dropdown
            id="tipo_cliente_id"
            v-model="data_cliente.tipo_cliente_id"
            :options="tipo_clientes"
            optionLabel="nombre"
            optionValue="codigo"
            placeholder="Seleccione un tipo cliente..."
          />
          <small class="p-invalid" v-if="errors.tipo_cliente_id">{{
            errors.tipo_cliente_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span><strong>PROVINCIA/UBICACI&Oacute;N: </strong></span>
          <Dropdown
            v-model="data_cliente.provincia_id"
            :options="provincias"
            optionLabel="nombre_municipio"
            optionValue="id"
            placeholder="Seleccione una provincia..."
            :filter="true"
          >
            <template #option="slotProps">
              <span
                >{{ slotProps.option.nombre_departamento }} **
                {{ slotProps.option.nombre_municipio }}</span
              >
            </template>
          </Dropdown>
        </div>
        <div class="field col-12 md:col-2">
          <span for="estado"
            ><strong>ESTADO: </strong><span class="p-invalid">*</span></span
          >
          <Dropdown
            id="estado"
            v-model="data_cliente.estado"
            :disabled="!data_cliente.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12 md:col-6">
          <span for="domicilio"><strong>DOMICILIO: </strong> </span>
          <Textarea
            id="domicilio"
            v-model="data_cliente.domicilio"
            required="true"
            rows="1"
            autoResize
          />
          <small class="p-invalid" v-if="errors.domicilio">{{
            errors.domicilio[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <span for="correo"
            ><strong>CORREO ELECTR&Oacute;NICO: </strong>
          </span>
          <InputText
            id="correo"
            v-model="data_cliente.correo"
            required="true"
          />
          <small class="p-invalid" v-if="errors.correo">{{
            errors.correo[0]
          }}</small>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        :label="data_cliente.id ? 'ACTUALIZAR' : 'GUARDAR'"
        :icon="data_cliente.id ? 'pi pi-pencil' : 'pi pi-save'"
        class="p-button-info p-button-lg"
        @click="guardarCliente"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import ClienteService from "@/service/ClienteService";
/* import TipoDocumentoIdentidadService from "@/service/TipoDocumentoIdentidadService"; */
export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    cliente: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  clienteService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_cliente: this.cliente,
      /* tipo_documento_selected: {}, */
      enviado: false,
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      tipo_clientes: [],
      provincias: [],
    };
  },
  mounted() {
    this.datos_necesarios_crear_cliente();
  },
  created() {
    this.clienteService = new ClienteService();
  },
  methods: {
    datos_necesarios_crear_cliente() {
      this.clienteService.datos_necesarios_crear_cliente().then((data) => {
        this.tipo_clientes = data.tipo_clientes || [];
        this.provincias = data.provincias || [];
      });
    },
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
      this.enviado = false;
    },
    guardarCliente() {
      this.errors = {};
      this.enviado = true;
      //editar cliente
      if (this.cliente.id) {
        let cliente_enviar = {
          ...this.data_cliente,
          estado: this.data_cliente.estado.value,
          /* tipo_documento: this.tipo_documento_selected.value, */
        };
        this.clienteService.updatedCliente(cliente_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_cliente;
        this.data_cliente = {
          ...this.data_cliente,
          estado: this.data_cliente.estado.value,
          /* tipo_documento: this.tipo_documento_selected.value, */
        };
        this.clienteService.sendClienteNuevo(this.data_cliente).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.data_cliente = tmp;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 10000,
            });

            this.$emit("actualizarListado", data.cliente);
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    cliente(val) {
      this.data_cliente = val;
      if (!this.data_cliente.tipo_cliente_id) {
        this.data_cliente.tipo_cliente_id = this.tipo_clientes[0].codigo;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
